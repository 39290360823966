const API_BASE = (process.env.NODE_ENV == 'production')?'https://api.legully.com/':(window.location.host.startsWith('localhost')?'http://localhost.theletstream.com:8011/':'https://e32proxier8011.theletstream.com/');
const AUTH_BASE = (process.env.NODE_ENV == 'production')?'https://api.legully.com/':(window.location.host.startsWith('localhost')?'http://localhost.theletstream.com:8011/':'https://e32proxier8011.theletstream.com/');
// console.log("Using API BASE as " + API_BASE)
// console.log("Using AUTH BASE as " + AUTH_BASE)

const ANALYTICS_HOSTNAME = (process.env.NODE_ENV == 'production')?'analytics.legully.com':(window.location.host.startsWith('localhost')?'localhostanalytics.theletstream.com:8012':'e32proxier8080analytics.theletstream.com');
const BM_HOSTNAME = (process.env.NODE_ENV == 'production')?'bm.legully.com':(window.location.host.startsWith('localhost')?'localhostbm.theletstream.com:8080':'e32proxier8080bm.theletstream.com');
const ACCOUNTS_HOSTNAME = (process.env.NODE_ENV == 'production')?'accounts.legully.com':(window.location.host.startsWith('localhost')?'localhostaccounts.theletstream.com:8014':'e32proxier8080accounts.theletstream.com');

const apiendpoints = {
    analytics_hostname: ANALYTICS_HOSTNAME,
    bm_hostname: BM_HOSTNAME,
    accounts_hostname: ACCOUNTS_HOSTNAME,

    api_base: API_BASE,
    api_base_noslash: API_BASE.slice(0, -1),
    auth_base: AUTH_BASE,
    auth_base_noslash: AUTH_BASE.slice(0, -1),

    // Accounts
    login: AUTH_BASE + 'accounts/api/login/',
    register: AUTH_BASE + 'accounts/register/',
    profile: AUTH_BASE + 'dashboard/profile/',
    get_users: API_BASE + 'dashboard/users/browse/',
    user_profile: API_BASE + 'accounts/user/profile/',
    user_role: API_BASE + 'accounts/user/role/',

    // Notifications
    // notifications_fcm: API_BASE + 'notifications/fcm/',
    notification_token_fcm_subscribe: `${API_BASE}notifications/subscribe/fcm/`,
    notifications: `${API_BASE}notifications/list/`,

    // DataStore
    entity_group: API_BASE + 'organisation/data/group/',
    entity_group_list: API_BASE + 'organisation/data/groups/',

    entity_tag: API_BASE + 'organisation/data/tag/',
    entity_tag_list: API_BASE + 'organisation/data/tags/',

    data_entity_list: API_BASE + 'organisation/data/my-entities/',
    data_entity: API_BASE + 'organisation/data/entity/',
    data_entity_action: API_BASE + 'organisation/data/entity/action/',
    data_entity_registrations: API_BASE + 'organisation/data/entity/registrations/',
    data_entity_registrations_raw: API_BASE + 'organisation/data/entity/registrations/raw/',
    data_entity_registration: API_BASE + 'organisation/data/entity/registration/',
    data_entity_registrations_data: API_BASE + 'organisation/data/entity/registrations/%i/data/',
    data_entity_registrations_filing_history: API_BASE + 'organisation/data/entity/registrations/%i/filing-history/',

    data_entity_compliancecalendar: API_BASE + 'organisation/data/entity/compliances/calendar/',
    data_entity_compliancecalendar_action: API_BASE + 'organisation/data/entity/compliances/calendar/%i/action/',
    data_entity_compliances: API_BASE + 'organisation/data/entity/compliances/browse/',

    registration_types: API_BASE + 'organisation/data/registration-types/',

    data_entity_compliance_registration_action: API_BASE + 'organisation/data/entity/compliances/%i/registration/%i/action/',
    data_entity_compliance: API_BASE + 'organisation/data/entity/compliances/entity-compliance/%i/',

    compliance: `${API_BASE}organisation/data/entity/compliances/`,
    compliance_list: `${API_BASE}organisation/data/entity/compliances/browse/`,

    compliance_group: `${API_BASE}organisation/data/entity/compliances/group/`,
    compliance_groups: `${API_BASE}organisation/data/entity/compliances/groups/`,

    compliance_date: `${API_BASE}organisation/data/entity/compliances/date/`,
    compliance_dates: `${API_BASE}organisation/data/entity/compliances/dates/`,

    compliance_date_extension: `${API_BASE}organisation/data/entity/compliances/date/extension/`,
    compliance_date_extensions: `${API_BASE}organisation/data/entity/compliances/date/extensions/`,

    compliance_field: `${API_BASE}organisation/data/entity/compliances/date/field/`,
    compliance_fields: `${API_BASE}organisation/data/entity/compliances/date/fields/`,

    compliance_calendar: `${API_BASE}organisation/data/entity/compliances/calendar/`,

    compliance_filing_data: `${API_BASE}organisation/data/entity/compliances/filing-data/`,
    compliance_filing_data_list: `${API_BASE}organisation/data/entity/compliances/filing-data/browse/`,


    data_entity_permission: API_BASE + 'organisation/data/entity/%i/permission/',
    data_entity_permissions: API_BASE + 'organisation/data/entity/permissions/',
    data_entity_permissions_entity_wise: API_BASE + 'organisation/data/entity/%i/permissions/',
    data_entity_tabledata: API_BASE + 'organisation/data/entities/',
    data_entity_registrations_gst: API_BASE + 'organisation/data/entity/%i/registrations/approvals/gst/',
    data_entity_registrations_gst_data: API_BASE + 'organisation/data/entity/%i/registrations/gst/',
    data_entity_registrations_gst_list: API_BASE + 'organisation/data/entity/%i/registrations/gst/',

    data_entity_registrations_pf: API_BASE + 'organisation/data/entity/%i/registrations/approvals/pf/',
    data_entity_registrations_pf_data: API_BASE + 'organisation/data/entity/%i/registrations/pf/',
    data_entity_registrations_pf_list: API_BASE + 'organisation/data/entity/%i/registrations/pf/',

    data_entity_registrations_esi: API_BASE + 'organisation/data/entity/%i/registrations/approvals/esi/',
    data_entity_registrations_esi_data: API_BASE + 'organisation/data/entity/%i/registrations/esi/',
    data_entity_registrations_esi_list: API_BASE + 'organisation/data/entity/%i/registrations/esi/',

    data_entity_registrations_pt: API_BASE + 'organisation/data/entity/%i/registrations/approvals/pt/',
    data_entity_registrations_pt_list: API_BASE + 'organisation/data/entity/%i/registrations/pt/',

    data_entity_registrations_lwf: API_BASE + 'organisation/data/entity/%i/registrations/approvals/lwf/',
    data_entity_registrations_lwf_list: API_BASE + 'organisation/data/entity/%i/registrations/lwf/',

    data_entity_registrations_income_tax: API_BASE + 'organisation/data/entity/%i/registrations/approvals/pan/',
    data_entity_registrations_income_tax_data: API_BASE + 'organisation/data/entity/%i/registrations/pan/',
    data_entity_registrations_income_tax_list: API_BASE + 'organisation/data/entity/%i/registrations/pan/',

    data_entity_registrations_tds: API_BASE + 'organisation/data/entity/%i/registrations/approvals/tds/',
    data_entity_registrations_tds_data: API_BASE + 'organisation/data/entity/%i/registrations/tds/',
    data_entity_registrations_tds_list: API_BASE + 'organisation/data/entity/%i/registrations/tds/',

    data_entity_registrations_fssai: API_BASE + 'organisation/data/entity/%i/registrations/approvals/fssai/',
    data_entity_registrations_fssai_list: API_BASE + 'organisation/data/entity/%i/registrations/fssai/',

    data_entity_registrations_irda: API_BASE + 'organisation/data/entity/%i/registrations/approvals/irda/',
    data_entity_registrations_irda_list: API_BASE + 'organisation/data/entity/%i/registrations/irda/',

    data_entity_registrations_trademark: API_BASE + 'organisation/data/entity/%i/registrations/approvals/trademark/',
    data_entity_registrations_trademark_data: API_BASE + 'organisation/data/entity/%i/registrations/trademark/',
    data_entity_registrations_trademark_list: API_BASE + 'organisation/data/entity/%i/registrations/trademark/',
    data_entity_registrations_trademark_status: API_BASE + 'organisation/data/entity/%i/registrations/trademark/%i/status/',

    data_entity_registrations_voterid: API_BASE + 'organisation/data/entity/%i/registrations/approvals/voter-id/',
    data_entity_registrations_pan: API_BASE + 'organisation/data/entity/%i/registrations/approvals/pan/',
    data_entity_registrations_pan_data: API_BASE + 'organisation/data/entity/%i/registrations/pan/',
    data_entity_registrations_aadhar: API_BASE + 'organisation/data/entity/%i/registrations/approvals/aadhar/',
    data_entity_registrations_passport: API_BASE + 'organisation/data/entity/%i/registrations/approvals/passport/',
    data_entity_registrations_driving_license: API_BASE + 'organisation/data/entity/%i/registrations/approvals/driving-license/',
    data_entity_registrations_cin: API_BASE + 'organisation/data/entity/%i/registrations/approvals/cin/',
    data_entity_registrations_llpin: API_BASE + 'organisation/data/entity/%i/registrations/approvals/llpin/',

    data_entity_compliances_slug: API_BASE + 'organisation/data/entity/%i/compliances/%s/',
    data_entity_compliance_file: API_BASE + 'organisation/data/entity/%i/compliance/file/',
    data_entity_compliance_unfile: API_BASE + 'organisation/data/entity/%i/compliance/unfile/',
    data_entity_compliance_applicable: API_BASE + 'organisation/data/entity/%i/compliance/applicable/',
    data_entity_compliance_file_data: API_BASE + 'organisation/data/entity/%i/compliance/file/%s/data/',
    data_entity_compliance_dates: API_BASE + 'organisation/data/entity/%i/compliance/dates/',
    data_entity_compliance_summary: API_BASE + 'organisation/data/entity/%i/compliance/summary/',
    data_entity_compliance_list: API_BASE + 'organisation/data/entity/%i/compliances/',


    data_entity_reports_tds_returns: API_BASE + 'organisation/data/entity/%i/reports/tds/returns/',
    data_entity_reports_tds_penalties: API_BASE + 'organisation/data/entity/%i/reports/tds/penalties/',
    data_entity_reports_tds_penalties_summary: API_BASE + 'organisation/data/entity/%i/reports/tds/penalties/summary/',
    data_entity_reports_tds_challans: API_BASE + 'organisation/data/entity/%i/reports/tds/challans/',
    data_entity_reports_tds_challan_amount: API_BASE + 'organisation/data/entity/%i/reports/tds/challan/%i/amount/',
    data_entity_reports_tds_challans_summary: API_BASE + 'organisation/data/entity/%i/reports/tds/challans/summary/',

    data_entity_reports_esi_employees: API_BASE + 'organisation/data/entity/%i/reports/esi/employees/',
    data_entity_reports_esi_contribution_summary: API_BASE + 'organisation/data/entity/%i/reports/esi/contribution-history/summary/',
    data_entity_reports_esi_contribution_detailed: API_BASE + 'organisation/data/entity/%i/reports/esi/contribution-history/detailed/',
    data_entity_reports_esi_challans: API_BASE + 'organisation/data/entity/%i/reports/esi/challans/',
    data_entity_reports_esi_challan_verification: API_BASE + 'organisation/data/entity/%i/reports/esi/challan-verification/',
    data_entity_reports_esi_epehchan: API_BASE + 'organisation/data/entity/%i/reports/esi/epehchan/',

    data_entity_reports_incometax_returns: API_BASE + 'organisation/data/entity/%i/reports/income-tax/returns/',
    data_entity_reports_incometax_e_proceedings: API_BASE + 'organisation/data/entity/%i/reports/income-tax/e-proceedings/',
    data_entity_reports_incometax_e_proceedings_detail: API_BASE + 'organisation/data/entity/%i/reports/income-tax/e-proceedings/detail/',
    data_entity_reports_incometax_form_26as: API_BASE + 'organisation/data/entity/%i/reports/income-tax/form-26as/',

    data_entity_reports_mca_documents: API_BASE + 'organisation/data/entity/%i/reports/mca/documents/',
    sms_create_mobile_number: API_BASE + 'organisation/data/entity/%i/mobile-number/',
    gst_return_status_list: API_BASE + 'organisation/data/gst/status/',

    document_generator_config: API_BASE + 'docgenerator/config/',
    document_generator_configs: API_BASE + 'docgenerator/configs/',
    document_generator_document: API_BASE + 'docgenerator/document/',
    document_generator_documents: API_BASE + 'docgenerator/documents/',
    document_generator_config_groups: API_BASE + 'docgenerator/config-groups/',

    //api end points for dsc tracker
    dsc_tracker: API_BASE + 'organisation/data/entity/%i/data/dsc/',
    dsc_tracker_list: API_BASE + 'organisation/data/dscs/',

    get_quota: API_BASE + 'quota/',

    entity_dashboards: API_BASE + 'organisation/data/entity/%i/dashboards/',

    data_entity_connectors_tally_balance_sheet: API_BASE + 'organisation/data/entity/%i/connectors/tally/balance-sheet/',
    data_entity_connectors_tally_trial_balance: API_BASE + 'organisation/data/entity/%i/connectors/tally/trial-balance/',
    data_entity_connectors_tally_profit_loss: API_BASE + 'organisation/data/entity/%i/connectors/tally/profit-loss/',
    data_entity_connectors_tally_ledger_vouchers: API_BASE + 'organisation/data/entity/%i/connectors/tally/ledger-vouchers/',
    data_entity_connectors_tally_ledgers: API_BASE + 'organisation/data/entity/%i/connectors/tally/ledgers/',
    data_entity_connectors_tally_get_ledger_vouchers: API_BASE + 'organisation/data/entity/%i/connectors/tally/ledger/',
    data_entity_connectors_tally_summary: API_BASE + 'organisation/data/entity/%i/connectors/tally/summary/',
    data_entity_connectors_tally_last_voucher: API_BASE + 'organisation/data/entity/%i/connectors/tally/last-voucher/',

    //exporter
    exporter_get: API_BASE + 'exporter/',
    exporter_list: API_BASE + 'exporter/list/',
    exporter_tally_data: API_BASE + 'exporter/entity/%i/tally-data/%i/',
    exporter_challan_summary: API_BASE + 'exporter/entity/%i/challan-summary/',
    exporter_compliance_calendar: API_BASE + 'exporter/calendar/export/',
    exporter_dsc: API_BASE + 'exporter/dsc/export/',
    exporter_create_file_bulk_password: API_BASE + 'exporter/bulkpassword/export/',

    //importer
    importer_list: API_BASE + 'importer/list/',
    data_entity_import_bulk_password: API_BASE + 'importer/passwordmanager/import/',
    bulk_entity_registration_import: API_BASE + 'importer/bulk/import/',

    import_dsc:  API_BASE + 'importer/dsc/import/',

    registration_trademarks: API_BASE + 'organisation/data/registrations/trademarks/',

    // reseller
    reseller_user_list: API_BASE + 'reseller/list/user/',

    board_meeting: API_BASE + 'organisation/data/entity/%i/boardmeeting/',
    board_meeting_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/list/',
    meeting_agenda_create: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/meetingagenda/',
    meeting_agenda_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/meetingagenda/list/',
    agenda_table_create: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/agenda/%s/agendatable/',
    agenda_table_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/agenda/%s/agendatables/',
    agenda_attendees_create: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/attendees/create/',
    agenda_attendees_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/attendees/list/',

    //attendees
    attendees_create: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/attendees/',
    attendees_edit: API_BASE + 'organisation/data/entity/%i/boardmeeting/attendees/%i/?status=a',
    attendees_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/attendees/list/',
    attendees_directors_list: API_BASE + 'organisation/data/entity/%i/boardmeeting/%s/suggestion/',

    //agenda_topic
    agenda_topic_create: API_BASE + 'agendatopic/agendatopic/',
    agenda_topic_edit: API_BASE + 'agendatopic/agendatopic/%i/',
    agenda_topic_list: API_BASE + 'agendatopic/agendatopic/list/',

    //agenda_topic_fields
    agenda_topic_field_create: API_BASE + 'agendatopic/%i/agendatopicfield/',
    agenda_topic_field_list: API_BASE + 'agendatopic/agendatopicfields/list/%i/',

    // agenda_topic_tabel
    agenda_topic_table: API_BASE + 'agendatopic/%i/table/',
    agenda_topic_table_list: API_BASE + 'agendatopic/%i/tables/',

    boardmeeting_bird_view: API_BASE + 'organisation/data/entity/%i/boardmeeting/bird/%s/',
    upload_boardmeeting_document: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/document/',
    boardmeeting_generate_document: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/document/generate/',
    boardmeeting_documents: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/documents/',

    board_meeting_pre_meeting: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/pre-meeting/',
    board_meeting_post_meeting: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/post-meeting/',
    board_meeting_end_meeting: API_BASE + 'organisation/data/entity/%i/boardmeeting/%i/end-meeting/',
    // sms
    sms_template_list: API_BASE + 'sms/template/',
    load_entity_for_sms: API_BASE + 'sms/entities/',
    mobile_number_api: API_BASE + 'sms/mobile/',
    sms_campaign: API_BASE + 'sms/campaign/',
    sms_campaign_list: API_BASE + 'sms/campaigns/',


    sheet_list: API_BASE + 'organisation/data/entity/%i/sheet/list/',
    sheet_data_list: API_BASE + 'organisation/data/entity/%i/sheet/%i/details/',
    sheet_data: API_BASE + 'organisation/data/entity/%i/sheet/%i/detail/',
    sheet_create: API_BASE + 'organisation/data/entity/%i/sheet/data/create/',
    sheet: API_BASE + 'organisation/data/entity/%i/sheet/data/',

    task_module: API_BASE + 'task/entity/%i/task/',
    task_module_tag_list: API_BASE + 'task/entity/%i/tag/list/',

    // organisation
    organisations: `${API_BASE}organisation/list/`,
    organisation: `${API_BASE}organisation/`,
    user_organisations: `${API_BASE}organisation/my-organisations/`,
    organisation_user: `${API_BASE}organisation/user/`,
    organisation_users: `${API_BASE}organisation/users/`,
    organisation_user_action: `${API_BASE}organisation/user/action/`,

    user_invite: `${API_BASE}organisation/users/invite/`,
    users_invited: `${API_BASE}organisation/users/invited/`,
    get_user_invite: `${API_BASE}organisation/users/invite/%s/`,

    // Org Reports
    reports_applicable_compliances: `${API_BASE}organisation/data/user-reports/applicable-compliances/`,
    reports_annual_filing_history: `${API_BASE}organisation/data/user-reports/annual-filing-history/`,
}

export default apiendpoints
