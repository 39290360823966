import Vue from 'vue'
import Router from 'vue-router'
import auth from "@/auth/authService";
import { api } from './utils/requests';
import store from "./store/store"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [

                {
                    path: '/',
                    redirect: '/dashboard',
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/dashboard',
                    name: 'dashboard-analytics',
                    component: () => import('./views/DashboardAnalytics.vue'),
                    meta: {
                        rule: 'subscriber'
                    }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import('./views/components/Notifications.vue'),
                    meta: {
                        rule: 'subscriber'
                    }
                },
                {
                    path: '/notifications/:id',
                    name: 'notification-single',
                    component: () => import('./views/components/NotificationsSingle.vue'),
                    meta: {
                        rule: 'subscriber'
                    }
                },

                // Organisation Level
                {
                    path: '/entities',
                    name: 'entity-list',
                    component: () => import('./views/organisation/Entity/List.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'My Entities', active: true},
                        ],
                        pageTitle: 'My Entites'
                    },
                    props: true
                },
                {
                    path: '/reports/applicable-compliances',
                    name: 'reports-applicable-compliances',
                    component: () => import('./views/organisation/Reports/ApplicableCompliances/Report.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Reports'},
                            { title: 'Applicable Compliances', active: true},
                        ],
                        pageTitle: 'Applicable Compliances'
                    },
                    props: true
                },
                {
                    path: '/reports/annual-filing-history/',
                    name: 'reports-annual-filing-history',
                    component: () => import('./views/organisation/Reports/AnnualFilingHistory/Report.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Reports'},
                            { title: 'Annual Filing History', active: true},
                        ],
                        pageTitle: 'Annual Filing History'
                    },
                    props: true
                },

                {
                    path: '/compliances/calendar',
                    name: 'compliancecalendar-user-home',
                    component: () => import('./views/organisation/Compliance/Calendar.vue'),
                    meta: {
                        rule: 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Compliance Calendar', active: true },
                        ],
                        pageTitle: 'Compliance Calendar'
                    }
                },
                {
                    path: '/entity/new',
                    name: 'entity-add',
                    component: () => import('./views/organisation/Entity/Edit.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Entities'},
                            { title: 'Add', active: true },
                        ],
                        pageTitle: 'Add Entity'
                    },
                    props: true
                },

                {
                    path: '/entity/:id',
                    component: () => import('./views/organisation/Entity/Base.vue'),
                    children: [
                        {
                            path: 'view',
                            name: 'view-entity',
                            component: () => import('./views/organisation/Entity/Home.vue'),
                            meta: {
                                'rule': 'subscriber',
                                breadcrumb: [
                                    { title: 'Home', url: '/' },
                                    { title: 'Entity', entity: {use_active: true, slug: 'view-entity'}},
                                    { title: 'View', active: true},
                                ],
                                pageTitle: 'Home'
                            },
                            props: true
                        },

                        // Entity Registration Routes Start
                        {
                            path: 'registrations',
                            name: 'entity-registrations',
                            component: () => import('./views/organisation/Entity/Registrations.vue'),
                            meta: {
                                'rule': 'subscriber',
                                breadcrumb: [
                                    { title: 'Home', url: '/' },
                                    { title: 'Entity', entity: {use_active: true, slug: 'view-entity'}},
                                    { title: 'Registrations', active: true},
                                ],
                                pageTitle: 'My Registrations'
                            },
                            props: true
                        },
                        {
                            path: 'registrations/:registration_type/manage',
                            name: 'entity-registration-manage',
                            component: () => import('./views/organisation/Entity/Registrations/Manage.vue'),
                            meta: {
                                'rule': 'subscriber',
                                breadcrumb: [
                                    { title: 'Home', url: '/' },
                                    { title: 'Entity', entity: {use_active: true, slug: 'view-entity'}},
                                    { title: 'Registrations', active: true},
                                ],
                                pageTitle: 'Manage Registration'
                            },
                            props: true
                        },
                        {
                            path: 'registrations/:registration_type/:registration_id/filing-history',
                            name: 'entity-registration-filing-history',
                            component: () => import('./views/organisation/Entity/Registrations/FilingHistory.vue'),
                            meta: {
                                'rule': 'subscriber',
                                breadcrumb: [
                                    { title: 'Home', url: '/' },
                                    { title: 'Entity', entity: {use_active: true, slug: 'view-entity'}},
                                    { title: 'Registrations'},
                                    { title: 'Filing History', active: true},
                                ],
                                pageTitle: 'Filing History'
                            },
                            props: true
                        },
                        // Entity Registration Routes End
                        // Compliance Calendar Routes Starts
                        {
                            path: 'compliancecalendar',
                            name: 'compliancecalendar-home',
                            component: () => import('./views/organisation/Compliance/Calendar.vue'),
                            meta: {
                                rule: 'subscriber',
                                breadcrumb: [
                                    { title: 'Home', url: '/' },
                                    { title: 'Entity', entity: {use_active: true, slug: 'view-entity'}},
                                    { title: 'Compliance Calendar', active: true },
                                ],
                                parent: 'compliancecalendar',
                                pageTitle: 'Compliance Calendar'
                            }
                        }
                    ]
                },


                // Organisation Level Ends

                // Admin
                {
                    path: '/admin/tags',
                    name: 'entity-tags',
                    component: () => import('./views/organisation/Tags/Tag.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Admin', },
                            { title: 'Tags', active: true},
                        ],
                        pageTitle: 'Manage Tags'
                    },
                    props: true
                },
                {
                    path: '/admin/groups',
                    name: 'entity-groups',
                    component: () => import('./views/organisation/Groups/Group.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Admin', },
                            { title: 'Groups', active: true},
                        ],
                        pageTitle: 'Manage Groups'
                    },
                    props: true
                },

                {
                    path: '/admin/users',
                    name: 'user-list',
                    component: () => import('./views/organisation/ListUser.vue'),
                    meta: {
                        rule: 'subscriber',
                        organisation_rules: ['a', ]
                    }
                },
                {
                    path: '/admin/users/edit/:user_id',
                    name: 'user-edit',
                    component: () => import('./views/organisation/EditUser.vue'),
                    meta: {
                        rule: 'subscriber',
                        organisation_rules: ['a', ]
                    }
                },

                {
                    path: '/admin/users/invite',
                    name: 'user-invite',
                    component: () => import('./views/organisation/InviteUser.vue'),
                    meta: {
                        rule: 'subscriber',
                        organisation_rules: ['a', ]
                    }
                },
                {
                    path: '/admin/users/invited/',
                    name: 'user-invited-list',
                    component: () => import('./views/organisation/InvitedUser.vue'),
                    meta: {
                        rule: 'subscriber',
                        organisation_rules: ['a', ]
                    }
                },

                // Admin Ends
                {
                    path: '/help',
                    name: 'help',
                    component: () => import('./views/pages/ContactUs.vue'),
                    meta: {
                        'rule': 'subscriber',
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Help', active: true},
                        ],
                        pageTitle: 'Help'
                    },
                    props: true
                },
            ],
        },
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                {
                    path: '/choose-organisation',
                    name: 'organisation-chooser',
                    component: () => import('./views/organisation/Chooser.vue'),
                    meta: {
                        rule: 'subscriber'
                    }
                },
                {
                    path: '/organisation/new',
                    name: 'organisation-new',
                    component: () => import('./views/organisation/Create.vue'),
                    meta: {
                        rule: 'subscriber',
                        skip_organisation: true
                    }
                },
                {
                    path: '/accounts/login/email',
                    name: 'page-login',
                    component: () => import('@/views/Authentication/Login.vue'),
                    meta: {
                        rule: 'public',
                        skip_organisation: true
                    }
                },
                {
                    path: '/accounts/register',
                    name: 'page-register',
                    component: () => import('@/views/pages/Register.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/organisation/users/invite/:token',
                    name: 'users-invite-token',
                    component: () => import('@/views/organisation/InviteReview.vue'),
                    meta: {
                        rule: 'subscriber',
                        skip_organisation: true
                    }
                },
                {
                    path: '/accounts/logout',
                    name: 'page-logout',
                    component: () => import('@/views/pages/Logout.vue'),
                    meta: {
                        rule: 'subscriber'
                    }
                },
                {
                    path: '/accounts/forgot-password',
                    name: 'page-forgot-password',
                    component: () => import('@/views/pages/ForgotPassword.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/errors/404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/errors/500',
                    name: 'page-error-500',
                    component: () => import('@/views/pages/Error500.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/errors/not-authorized',
                    name: 'page-not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/errors/offline',
                    name: 'page-offline',
                    component: () => import('@/views/pages/Offline.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/errors/404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
    if(store.state.active_service_worker){
        store.state.active_service_worker.update()
    }
})

router.beforeEach((to, from, next) => {
  // console.log("Accessing ", to.path, " and You're online? ", navigator.onLine)

  const next_url = to.fullPath

  if(!navigator.onLine) {
      if(to.path != '/errors/offline'){
          // console.log("Redirected to offline")
          return next({name: 'page-offline', params: {next: next_url}})
      }
      else
          return next()
  }

  if (!store.state.organisation.organisation && to.name != "organisation-chooser" && !to.meta.skip_organisation) {
      next({name: 'organisation-chooser', query: {next: next_url}})
  } else {
      if(to.meta.rule == 'admin' || to.meta.rule == 'subscriber'){
          auth.isAuthenticated().then( (res) => {
              next()
          }, (err) => {
              if (to.name === 'forgot-password') {
                  next()
              } else{
                  console.log('Redirecting')
                  return next({name: 'page-login', query: {next: next_url}})
              }
          })
      }
      else {
          next()
      }
  }

});

export default router
